import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TopHeader from "../components/top-header"
import { Row, Container, Col } from 'react-bootstrap/'
import { Table } from "semantic-ui-react"
import retmesMms from "../images/MMS.svg"
import retmesPms from "../images/PMS.svg"
import retmesOee from "../images/OEE.svg"
import retmesMlq from "../images/mIQ.svg"
import varIcon from "../images/var.svg"
import yokIcon from "../images/yok.svg"
import opsiyonelIcon from "../images/opsiyonel.svg"
import ReactTooltip from 'react-tooltip'
import { FaInfoCircle } from 'react-icons/fa';
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-intl"

class Cozumler extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const InfoStyle = {
      marginRight: '10px',
    };
    const { intl } = this.props

    return (
      <Layout>
        <TopHeader />
        <SEO
          title={intl.formatMessage({ id: "solutions_title" })}
          description='Yapay Zeka Destekli MES ve MOM Entegrasyon Servisleri, Rest API, Proses Optimizasyonu, Tarihsel Veri Depolama Servisleri'
        />
        <Container>
          <ReactTooltip
            multiline='true'
            wrapper='span '
          />
          <h1 className='page-title'><FormattedMessage id="solutions_title" /></h1>
          <div className='table-wrapper'>
            <Table selectable striped className='cozumler-table'>
              <thead>
                <tr>
                  <th></th>
                  <th className='solutions-logo'><Link to='/cozumler/uretim-izleme-sistemi'><img src={retmesMms} alt="retmes uretim izleme sistemi" /></Link></th>
                  <th className='solutions-logo'><Link to='/cozumler/enerji-yonetim-sistemi'><img src={retmesPms} alt="retmes enerji yonetim sistemi" /></Link></th>
                  <th className='solutions-logo'><Link to='/cozumler/toplam-ekipman-verimliligi'><img src={retmesOee} alt="retmes toplam ekipman verimlilği" /></Link></th>
                  <th className='solutions-logo'><Link to='/cozumler/yapay-zeka-destekli-uretim'><img src={retmesMlq} alt="retmes toplam ekipman verimliliği" /></Link></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_1_tip" })} /><FormattedMessage id="solutions_table_module_1" /></p></td>
                  <td><img classname='var-icon' classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_2_tip" })} /><FormattedMessage id="solutions_table_module_2" /></p></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_3_tip" })} /><FormattedMessage id="solutions_table_module_3" /></p></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_4_tip" })} /><FormattedMessage id="solutions_table_module_4" /></p></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_5_tip" })} /><FormattedMessage id="solutions_table_module_5" /></p></td>
                  <td><img src={opsiyonelIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img src={opsiyonelIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_6_tip" })} /><FormattedMessage id="solutions_table_module_6" /></p></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_7_tip" })} /><FormattedMessage id="solutions_table_module_7" /></p></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img src={opsiyonelIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_8_tip" })} /><FormattedMessage id="solutions_table_module_8" /></p></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_9_tip" })} /><FormattedMessage id="solutions_table_module_9" /></p></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_10_tip" })} /><FormattedMessage id="solutions_table_module_10" /></p></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_11_tip" })} /><FormattedMessage id="solutions_table_module_11" /></p></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_12_tip" })} /><FormattedMessage id="solutions_table_module_12" /></p></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_13_tip" })} /><FormattedMessage id="solutions_table_module_13" /></p></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_14_tip" })} /><FormattedMessage id="solutions_table_module_14" /></p></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_15_tip" })} /><FormattedMessage id="solutions_table_module_15" /></p></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_16_tip" })} /><FormattedMessage id="solutions_table_module_16" /></p></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_17_tip" })} /><FormattedMessage id="solutions_table_module_17" /></p></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_18_tip" })} /><FormattedMessage id="solutions_table_module_18" /></p></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img src={yokIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_19_tip" })} /><FormattedMessage id="solutions_table_module_19" /></p></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_20_tip" })} /><FormattedMessage id="solutions_table_module_20" /></p></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_21_tip" })} /><FormattedMessage id="solutions_table_module_21" /></p></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_23_tip" })} /><FormattedMessage id="solutions_table_module_23" /></p></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                  <td><img classname='var-icon' src={varIcon} alt="table-icon" /></td>
                </tr>
                <tr>
                  <td><p className='table-content'><FaInfoCircle style={InfoStyle} data-tip={intl.formatMessage({ id: "solutions_table_module_24_tip" })} /><FormattedMessage id="solutions_table_module_24" /></p></td>
                  <td><img src={opsiyonelIcon} alt="table-icon" /></td>
                  <td><img src={opsiyonelIcon} alt="table-icon" /></td>
                  <td><img src={opsiyonelIcon} alt="table-icon" /></td>
                  <td><img src={opsiyonelIcon} alt="table-icon" /></td>
                </tr>
              </tbody>
              <div className='table-info'>
                <img src={varIcon} alt="Available" /> <p>: <FormattedMessage id="available" /></p>
                <img src={yokIcon} alt="None" /> <p>: <FormattedMessage id="none" /></p>
                <img src={opsiyonelIcon} alt="Optional" /><p>: <FormattedMessage id="optional" /></p>
              </div>
            </Table>
          </div>
          <Row className='home-row-2'>
            <Col sm style={{ alignSelf: 'center', marginTop: '5em,' }} className='head-col-1'  >
              <h1 style={{ fontWeight: '200', paddingTop: '30px', textAlign: '-webkit-center' }}><FormattedMessage id="solutions_ad_title_h1" /></h1><br />
              <p style={{ fontWeight: '200', paddingTop: '30px', textAlign: '-webkit-center' }}><FormattedMessage id="solutions_ad_desc_p" /></p><br />
              <Link to='/iletisim'> <div className='retmes-detail-button'><FormattedMessage id="get_support" /></div></Link>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default injectIntl(Cozumler)
